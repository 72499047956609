$(document).ready(initPage);

function initPage(){
    $('.select-container select')
      .focus(function() {
        $(this).parent().addClass('focused');
      })
      .blur(function() {
        $(this).parent().removeClass('focused');
      });

    /* START Top menu */

    $('.topmenu li.dropdown > span').click(function(){
      $(this).parent().toggleClass('active');
      $(this).parent().siblings().removeClass('active');
    });

    $('.toggler').click(function(){
      $('html, body').toggleClass('menu-mobile');
    });

    isScrolled();

    $(window).on("scroll", throttle(function(){
      isScrolled();
    }, 10));

    function isScrolled() {
      var scrollPos = $(window).scrollTop();
      if (scrollPos > 10) {
        $('body').addClass('scrolled');
      } else {
        $('body').removeClass('scrolled');
      };
    }

    function throttle (callback, limit) {
      var wait = false;  
      return function () {
        if (!wait) {
          callback.call();
          wait = true;
          setTimeout(function () {
            wait = false;
          }, limit);
        }
      }
    }

    /* END Top menu */

    /* START Footer */

    $('.footer-menu .col-h').click(function(){
      $(this).siblings('ul').toggleClass('active');
    });

    /* END Footer */



    /* START Home sliders */

    var toolsSwiper = new Swiper('.tools-swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 30,
      slidesOffsetBefore: 146,
      centeredSlides: true,
      loop: true,
      grabCursor: true,
      pagination: {
        el: '.tools-swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesOffsetBefore: 0,
        },
        992: {
          slidesOffsetBefore: 146
        }
      }
    });

    var cellsSwiper = new Swiper('.cells-swiper-container', {
      slidesPerView: 'auto',
      // slidesPerColumn: 2,
      spaceBetween: 40,
      centeredSlides: true,
      loop: true,
      grabCursor: true,
      pagination: {
        el: '.cells-swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        320: {
          spaceBetween: 30
        },
        992: {
          spaceBetween: 40
        }
      }
    });


    $(window).resize(function(){
      cellsSwiper.update();
    });

    /* END Home sliders */

    /* START various */

    var benefitsSwiper = new Swiper('.posbenefits-swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 30,
      grabCursor: true,
      pagination: {
        el: '.posbenefits-swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        320: {
          centeredSlides: true,
          loop: true,
        },
        992: {
          centeredSlides: false,
          loop: false,
        }
      }
    });


    // if($('.bloglist').length) {
    //   const instance = Bricks({
    //     container: '.bloglist',
    //     packed: 'packed',
    //     sizes: [
    //       { columns: 3, gutter: 30 },
    //       { mq: '768px', columns: 3, gutter: 30 },
    //       { mq: '1024px', columns: 3, gutter: 30 }
    //     ]
    //   });
    // }

    if($('.postlist').length) {
      setTimeout(function(){
        let magicGrid = new MagicGrid({
          container: ".postlist",
          static: true,
          animate: false,
          gutter: 25
        });

        magicGrid.listen();
      }, 100);
    }

    /* Payments */

    var tabId, activeTab;

    $('.tabs ul li').click(function(){
      tabId = $(this).attr('data-tab');
      activeTab = '.tabs ul li[data-tab=' + tabId + ']';

      $('.tabs ul li').removeClass('active');
      $('.tabs-content-item').removeClass('active');

      $(activeTab).addClass('active');
      $("#"+tabId).addClass('active');
    });

    var paymentsbenefitsSwiper = new Swiper('.payments-benefits-swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 30,
      grabCursor: true,
      centeredSlides: true,
      loop: true,
      pagination: {
        el: '.payments-benefits-swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        320: {
          loop: false,
        },
        992: {
          loop: true,
        }
      }
    });

    $('.faq-block .faq-h').click(function(){
      $(this).parent().toggleClass('active');
    });
}